/* vietnamese */
@font-face {
  font-family: '__Epilogue_fff1a5';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://cdn-assets.dzcdn.net/account/_next/static/media/17e0992af308dbf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Epilogue_fff1a5';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://cdn-assets.dzcdn.net/account/_next/static/media/5e34f549e172cb28-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Epilogue_fff1a5';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://cdn-assets.dzcdn.net/account/_next/static/media/8347d131139854e0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Epilogue_Fallback_fff1a5';src: local("Arial");ascent-override: 71.15%;descent-override: 21.16%;line-gap-override: 0.00%;size-adjust: 111.04%
}.__className_fff1a5 {font-family: '__Epilogue_fff1a5', '__Epilogue_Fallback_fff1a5';font-style: normal
}

.grecaptcha-badge {
	visibility: hidden;
}

#captcha-id {
	margin-top: 0;
}

@keyframes floating {
	from {
		transform: translateY(-10px);
	}

	to {
		transform: translateY(10px);
	}
}

@keyframes bounceScale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

