.grecaptcha-badge {
	visibility: hidden;
}

#captcha-id {
	margin-top: 0;
}

@keyframes floating {
	from {
		transform: translateY(-10px);
	}

	to {
		transform: translateY(10px);
	}
}

@keyframes bounceScale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}
